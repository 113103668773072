<template>

  <item-text title="Datums" :text="item.check_date" />

  <item-text title="Tips">
    <template v-slot:content>
        <Badge :text="item.type.name" :small="true" :className="`${statusColor(item.type.id)} mr-2 mb-2 md:mb-0`" />
    </template>
  </item-text>

  <item-text title="Noliktava" :text="item.warehouse.name" />
  <item-text title="Autors" :text="item.author.name" />
  <item-text title="Faila nosaukums" :text="item.filename" />
</template>

<script>
import Colors from "@/services/Helpers/colors";
import ItemText from "@/components/Components/ItemText"
import Badge from "@/components/Components/Badge"

export default {
  name: "ShowStockCheckDetails",
  components: {
    ItemText,
    Badge,
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  methods: {
    statusColor(id) {
        return Colors.statusColor(id)
    },
  }
}
</script>

<style>

</style>
<template>
  <item-card mb="5">
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Pamatdati
      </h3>
    </template>

    <template v-slot:buttons>

      <template v-if="item.has_xml">
          <template v-if="loading">
            <div class="w-10 h-10 relative flex items-center justify-center h-full">
                <Loading />
            </div>
          </template>

          <template v-else>
            <Button icon="download" @click="downloadXML" />
          </template>
      </template>

      <Button v-if="item.allow_delete" icon="delete" @click="deleteStockCheck" />

    </template>


    <template v-slot:content>
      <ShowStockCheckDetails :item="item" />
    </template>

  </item-card>

</template>

<script>
import {mapGetters} from "vuex"
import ItemCard from "@/components/Components/ItemCard"
import ShowStockCheckDetails from "@/components/Stock/StockChecks/StockCheckDetails/ShowStockCheckDetails"
import Loading from "@/components/Components/Loading";

export default {
  name: "StockCheckDetails",
  components: {
    ItemCard,
    ShowStockCheckDetails,
    Loading,
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      loading: "loading",
    })
  },
  methods: {
    downloadXML() {
      this.$store.dispatch('getStockCheckXML', this.item.id)
    },
    deleteStockCheck() {
      if(confirm('Vai esi drošs?')){
        this.$Progress.start()
        this.$store.dispatch('deleteStockCheck', this.item.id)
      }
    },
  }
}
</script>

<style>

</style>